import React from 'react';
import PropTypes from 'prop-types';
import {
  ITEM_TYPE_TO_COMPONENT_MAP,
  ITEM_SIZE
} from '#/components/Item/itemHelper';
import { actionClickItem } from '#/services/analytics';
import ContentGrid from '#/components/ContentGrid/ContentGrid';
import useI18n from '#/hooks/useI18n';

const getCarouselConfig = ({ itemType, config, carouselWidth }) => {
  const width = carouselWidth || 1880; // 1880 to take padding into consideration (1920-40)
  const spacing = 15;
  const itemSize = ITEM_SIZE[itemType];
  const { width: itemWidth, height: itemHeight } = {
    ...itemSize,
    ...config.itemOptions
  };

  return {
    itemHeight,
    itemWidth,
    spacing,
    width
  };
};

const CarouselShelf = ({
  config = {},
  carouselWidth,
  ds,
  items,
  itemType,
  nav = {},
  onClick
}) => {
  const { currentLocale = {} } = useI18n();
  const { dir } = currentLocale;

  const carouselConfig = getCarouselConfig({
    itemType,
    config,
    carouselWidth
  });
  const Item = ITEM_TYPE_TO_COMPONENT_MAP[itemType];
  const { headPadding = 0, tailPadding = 0 } = config;
  // const useInternalArrows =
  //   items.length * carouselConfig.itemWidth +
  //     items.length * carouselConfig.spacing >=
  //   carouselConfig.width;

  return (
    <ContentGrid
      data={items}
      ds={ds}
      nav={{
        ...nav,
        id: config.id || nav.id,
        useLastFocus: true
      }}
      keyProperty="id"
      itemWidth={carouselConfig.itemWidth}
      itemHeight={carouselConfig.itemHeight}
      horizontal
      headPadding={headPadding}
      tailPadding={tailPadding}
      spacing={carouselConfig.spacing}
      width={carouselConfig.width}
      onClick={item => {
        actionClickItem(item.data, config.displayText);
        return onClick(item.data);
      }}
      DisplayComponent={Item}
      displayComponentProps={{ type: itemType }}
      initialState={{
        // id: items?.[0]?.id,
        position: headPadding
      }}
      // useInternalArrows={useInternalArrows}
      dir={dir}
      buffer={6}
    />
  );
};

CarouselShelf.propTypes = {
  config: PropTypes.object,
  carouselWidth: PropTypes.number,
  ds: PropTypes.object,
  itemType: PropTypes.string,
  items: PropTypes.array,
  nav: PropTypes.object,
  onClick: PropTypes.func
};

export default CarouselShelf;
